import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from 'react-helmet';
import Obfuscate from 'react-obfuscate';

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}

const linkStyle = {
  color: "#5D3565",
  fontWeight: "bold",
  fontSize: 24,
  justifyContent: "center",
  textDecoration: "none"
}

const albumStyle = {
  textAlign: "center",
  marginBottom: 24
}

// types
interface Album {
  name: string;
  type: string;
  releaseYear: number;
  imgSrc: string;
  spotifyURL?: string;
  appleMusicURL: string;
  amazonMusicURL: string;
  tidalURL?: string;
  dominantColor: string;
}



// data
const albums: Album[] = [
  {
    name: "Bandette",
    type: "LP",
    releaseYear: 2016,
    imgSrc: "bandette.jpg",
    dominantColor: "#fff",
    spotifyURL: "https://open.spotify.com/album/0yZHE7Mic1UpHoYRrDKfQr",
    appleMusicURL: "https://music.apple.com/us/album/bandette/1127408551?uo=4&app=music&at=1001lry3&ct=dashboard",
    amazonMusicURL: "http://www.amazon.com/gp/product/B01HHEB18Q/?tag=distrokid06-20",
    tidalURL: "https://tidal.com/browse/album/62341707"
  },
  {
    name: "Cages",
    type: "LP",
    releaseYear: 2019,
    imgSrc: "cages.jpg",
    dominantColor: "#5D3565",
    spotifyURL: "https://open.spotify.com/album/6uz16lGwQ1EuqVnSYf19dt",
    appleMusicURL: "https://itunes.apple.com/us/album/cages/1454459303?uo=4&app=music&at=1001lry3&ct=dashboard",
    amazonMusicURL: "http://www.amazon.com/gp/product/B07P7QC5G2/?tag=distrokid06-20",
    tidalURL: "http://www.tidal.com/album/104876968"
  },
  {
    name: "Lovesick",
    type: "EP",
    releaseYear: 2020,
    imgSrc: "lovesick.jpg",
    dominantColor: "#F9E662",
    spotifyURL: "https://open.spotify.com/album/02u5HdLqUAalhFwSkFs8sl",
    appleMusicURL: "https://music.apple.com/us/album/lovesick-ep/1503596878?uo=4&app=music&at=1001lry3&ct=dashboard",
    amazonMusicURL: "http://www.amazon.com/gp/product/B0865VQKT9/?tag=distrokid06-20",
  },
  {
    name: "Lonely Christmas",
    type: "Single",
    releaseYear: 2021,
    imgSrc: "lonelyxmas.jpg",
    dominantColor: "#4E4B2B",
    spotifyURL: "https://open.spotify.com/album/5B3WgzuIj7wGFAFAMMLjiN",
    appleMusicURL: "https://music.apple.com/us/album/lonely-christmas-single/1598131069?uo=4&app=music&at=1001lry3&ct=dashboard",
    amazonMusicURL: "http://www.amazon.com/gp/product/B09MTZSVY3/?tag=distrokid06-20"
  },
  {
    name: "Sweet Dreams",
    type: "LP",
    releaseYear: 2022,
    imgSrc: "sweetdreams.jpg",
    dominantColor: "#FD62A3",
    spotifyURL: "https://open.spotify.com/album/5kc2mz7l43sqW7eeStA8uJ?si=jUimssfkQiuuTqkBOHCtQg",
    appleMusicURL: "https://music.apple.com/us/album/sweet-dreams-ep/1630536518",
    amazonMusicURL: "https://www.amazon.com/music/player/albums/B0B4GBQL3S"
  }

]

const socialIconSize = 40;




// markup
const IndexPage = () => {

  return (
    <main style={pageStyles}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Bandette The Band</title>
        <link rel="canonical" href="http://bandettetheband.com" />
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Nunito:wght@300&display=swap');
        </style>
      </Helmet>
      <title>Bandette The Band</title>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <StaticImage src="../images/bandette-square.jpg" alt="Bandette The Band Logo" 
          style={{borderRadius: 100, margin: 32}}
          width={200} 
          height={200} 
        />
        <span style={{ padding: 16 }}>
          <a href="https://instagram.com/bandettetheband" style={{padding: 8}} target={"_blank"}>
            <StaticImage src="../images/instagram.svg" alt="Bandette on Instagram" width={socialIconSize} height={socialIconSize} />
          </a>
          <a href="https://www.facebook.com/BandetteTheBand" style={{padding: 8}} target={"_blank"}>
            <StaticImage src="../images/facebook.svg" alt="Bandette on Facebook" width={socialIconSize} height={socialIconSize} />
          </a>
          <a href="https://bandette.bandcamp.com/follow_me" style={{padding: 8}} target={"_blank"}>
            <StaticImage src="../images/bandcamp.png" alt="Follow Bandette on Bandcamp" width={socialIconSize} height={socialIconSize} />
          </a>  
          <a href="https://itunes.apple.com/artist/bandette/1127409046" style={{padding: 8}} target={"_blank"}>
            <StaticImage src="../images/applemusic.png" alt="Bandette on Apple Music" width={socialIconSize} height={socialIconSize} />
          </a>
          <a href="https://open.spotify.com/user/3XOa6fs7E8XowyCIX5Hgyo" style={{padding: 8}} target={"_blank"}>
            <StaticImage src="../images/spotify.png" alt="Bandette on Spotify" width={socialIconSize} height={socialIconSize} />
          </a>

        </span>
        <span style={{ padding: 16, textAlign: 'center' }}>
          <a style={linkStyle} href={'#Shows'}>Shows</a>&nbsp; &nbsp;
          <a style={linkStyle} href={'#Music'}>Music</a>&nbsp; &nbsp;
          <a style={linkStyle} href={'#About'}>About Us</a>&nbsp; &nbsp;
          <a style={linkStyle} href={'#Contact'}>Contact </a>
        </span>
      </div>
      <span id="Shows">
        <h2 >Upcoming Shows</h2>
        <span>
          <em>check back soon!</em>
        </span>
      </span>
      <span id="Music">
        <h2>Music</h2>
        <ul id="albums" className="albumsList">
          {albums.reverse().map((album) => {
            return (
              <li style={albumStyle}>
                <img src={album.imgSrc} alt={`${album.name} album art`} width={200} height={200} />
                <h4 style={{color: album.dominantColor, margin: 0, fontSize: '1.4em'}}>{album.name}</h4>
                <h5 style={{margin: 0, marginBottom: 6}}>{`(${album.type} - ${album.releaseYear})`}</h5>
                <a href={album.appleMusicURL} style={{paddingRight: 4}}>
                  <StaticImage src="../images/applemusic.png" width={30} height={30} alt={`${album.name} - Apple Music Link`} />
                </a>
                {album.tidalURL && (
                  <a href={album.tidalURL} style={{paddingRight: 4}}>
                    <StaticImage src="../images/tidal.png" width={30} height={30} alt={`${album.name} - Tidal Link`} />
                  </a>
                )}
                {album.spotifyURL && (
                  <a href={album.spotifyURL} style={{paddingRight: 4}}>
                    <StaticImage src="../images/spotify.png" width={30} height={30} alt={`${album.name} - Spotify Link`} />
                  </a>
                )}
                <a href={album.amazonMusicURL}>
                  <StaticImage src="../images/amazon.png" width={30} height={30} alt={`${album.name} - Amazon Music Link`} />
                </a>
              </li>
            );
          })}

        </ul>
        <p style={{ fontSize: '14px', fontStyle: 'italic', padding: 32 }}>Bandette, Cages, Lovesick, and Sweet Dreams album artwork by LA-based artist <a href="https://jawshsmyth.art" style={{color: "#5D3565"}}>Jawsh Smyth</a></p>
      </span>
      <span id="About">
        <h2>About Bandette</h2>
        <div style={{margin: 32}}>
          <p>Bandette is an LA based band of four long time friends who write and perform indie rock songs about sex, booze, and rocky relationships – with pop hooks that make them instantly familiar.</p>
          <p>Like pieces from four corners of a jigsaw puzzle, the members of Bandette found that when they fit together they formed a new and vivid image that was colorful, brooding, ecstatic, and raw.</p>
          <p>Lead singer and head songwriter Christina Naza pulls no punches with earnest lyrics and sultry vocal stylings. Not merely the front woman, Naza invites audiences to confront strong emotions head-on, squash them with humor, or drown them in a glass of Bourbon.</p>
          <p>While Naza holds court, the instrumentalists of Bandette meld their backgrounds to form a confluence of musical styles that support her pure and soulful delivery.</p>
          <p>Jos Ehrichmann (Bass, Backing Vox) drives the low end, Bennett Kolasinski (Guitar, Backing Vox) brings a 90’s vibe, while Colin Simson (Drums, Backing Vox) lays down a hard rock foundation.</p>
        </div>
      </span>
      <span id="Contact">
        <h2>Contact: <Obfuscate email={"bandettetheband@gmail.com"} /></h2>        
      </span>

    </main>
  )
}

export default IndexPage
